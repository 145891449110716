import * as React from "react";
import { useLocation } from "@reach/router";
import "../styles/layout-styles.css";
import "../styles/global.css";
import Navbar from "./navBar";
import Footer from "./footer";

const Layout = ({ children }) => {
  const location = useLocation();

  // Check if the current path is the Randomizer page
  const isRandomizerPage = location.pathname === "/randomizer/";

  return (
    <>
      {!isRandomizerPage && <Navbar />}
      <main className="main">{children}</main>
      {!isRandomizerPage && (
        <section id="contact">
          <Footer />
        </section>
      )}
    </>
  );
};

export default Layout;
